import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Keyframes, animated } from "react-spring/renderprops";
import delay from "delay";
import projects from "../../data/projects.json";

const Overlay = Keyframes.Spring({
  open: { delay: 0, x: 0 },
  close: async call => {
    await delay(250);
    await call({ delay: 0, x: -100 });
  }
});

const Inner = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

const Content = Keyframes.Trail({
  open: { x: 0, opacity: 1, delay: 100 },
  close: { x: -100, opacity: 0, delay: 0 }
});

const Navigation = styled(animated.div)`
  background: black;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  overflow: auto;
  will-change: transform;
  padding: 0 4rem 0 8rem;

  .box {
    cursor: pointer;
    position: relative;
    width: 100%;
    padding: 1rem 0;
    will-change: transform;
    text-align: left;
  }

  .nav-item {
    display: block;
    text-transform: uppercase;
    font-family: var(--font-headline);
    transition: 0.2s;
    transform-origin: 50% 50%;
    will-change: transform;

    &:hover {
      filter: blur(2px);
      transform: scale(1.1);
    }

    ${breakpoint("mobile")`
      font-size: 1.5rem;
      margin: 0.75rem;
    `};
    ${breakpoint("tablet")`
      font-size: 2rem;
      margin: 1rem;
    `};
  }
`;

export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  componentWillReceiveProps(props) {
    this.setState({ open: props.open });
  }

  toggle = () => this.setState(state => ({ open: !state.open }));

  render() {
    const { open } = this.props;
    const state = this.state.open ? "open" : "close";
    const navItems = [];
    projects.map(project => {
      navItems.push(project.client);
    });

    return (
      <Overlay native state={state}>
        {({ x }) => (
          <Navigation
            style={{
              transform: x.interpolate(x => `translate3d(${x}%,0,0)`)
            }}
          >
            <Inner>
              <Content
                native
                items={navItems}
                keys={navItems.map((_, i) => i)}
                reverse={!this.state.open}
                state={state}
              >
                {item => ({ x, opacity }) => (
                  <animated.div
                    onClick={this.props.toggleMenu}
                    className="box"
                    style={{
                      opacity,
                      transform: x.interpolate(x => `translate3d(${x}px,0,0)`)
                    }}
                  >
                    <NavLink className="nav-item" to={`/work/${item}`}>
                      {item}
                    </NavLink>
                  </animated.div>
                )}
              </Content>
            </Inner>
          </Navigation>
        )}
      </Overlay>
    );
  }
}
