import React, { Component } from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import HamburgerMenu from "react-hamburger-menu";
import { NavLink } from "react-router-dom";
import { Trail, animated } from "react-spring/renderprops";
import * as easings from "d3-ease";

import Nav from "../Nav";

const Head = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 100;

  a {
    color: white;
    text-decoration: none;
  }

  ${breakpoint("mobile")`
    padding: 0.5rem;
  `};
  ${breakpoint("tablet")`
    padding: 1rem;
  `};
`;

const Hamburger = styled.div`
  position: absolute;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  cursor: pointer;
  height: 50px;
  width: 50px;
  padding: 2rem 1rem 1rem;
  cursor: pointer;

  ${breakpoint("mobile")`
    margin-left: 10px;
    top: 35vh;
  `};
  ${breakpoint("tablet")`
    margin-left: 9px;
    top: 45vh;
  `};

  &:hover {
    span {
      &:first-child {
        transform: translate3d(0, -2px, 0) !important;
      }
      &:last-child {
        transform: translate3d(0, 18px, 0) !important;
      }
    }

    &.open {
      span {
        &:first-child {
          transform: translate3d(0, 8px, 0) rotate(45deg) scale(0.9) !important;
        }
        &:last-child {
          transform: translate3d(0, 8px, 0) rotate(-45deg) scale(0.9) !important;
        }
      }
    }
  }
`;

const About = styled.div`
  position: absolute;
  left: 50%;
  line-height: 0.6;
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: 0 0;
  text-transform: uppercase;
  font-size: 0.8rem;
  color: white;
  text-decoration: none;

  ${breakpoint("mobile")`
    top: 75vh;
    margin-left: -2px;
  `};
  ${breakpoint("tablet")`
    top: 85vh;
  `};
`;

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggleMenu = () => {
    this.setState({
      open: !this.state.open
    });
  };

  closeMenu = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const items = [
      <div className="brand">
        <NavLink className="nav-item" key="home" to="/">
          <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1">
            <defs />
            <g id="2019" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-20.000000, -29.000000)">
                <g id="Logo" transform="translate(20.000000, 29.000000)">
                  <rect id="Rectangle-2" fill="#FFFFFF" x="0" y="0" width="40" height="40" />
                  <text
                    id="M"
                    transform="translate(20, 22) rotate(-90) translate(-20, -22)"
                    fontFamily="NettoOT, Netto OT"
                    fontSize="24"
                    fontWeight="normal"
                    fill="#000000"
                  >
                    <tspan x="13" y="30">
                      M
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </svg>
        </NavLink>
      </div>,
      <Hamburger className={this.state.open ? "open" : ""}>
        <HamburgerMenu
          isOpen={this.state.open}
          menuClicked={this.toggleMenu.bind(this)}
          width={30}
          height={16}
          strokeWidth={1}
          rotate={-90}
          color="white"
          borderRadius={0}
          animationDuration={0.5}
        />
      </Hamburger>,
      <About>
        <NavLink onClick={this.closeMenu} className="nav-item slash" key="about" to="/about">
          About
        </NavLink>
      </About>
    ];

    return (
      <Head>
        <Nav open={this.state.open} toggleMenu={this.toggleMenu} />
        <Trail
          delay={2500}
          items={items}
          keys={items.map((_, i) => i)}
          from={{ opacity: 0, transform: "translate3d(0,-40px,0)" }}
          to={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
          config={{ tension: 180, friction: 30, easing: easings.easeCubic }}
        >
          {item => props => <animated.div style={props} children={item} />}
        </Trail>
      </Head>
    );
  }
}
