import { gui, webgl, assets } from "../../../context";
// import animate from "@jam3/gsap-promise";
import { TimelineLite, Expo, Back } from "gsap";
import Simulation from "./Simulation";
import SimulationShader from "./SimulationShader";
import RenderShader from "./RenderShader";

// tell the preloader to include these assets
// we need to define this outside of our class, otherwise
// it won't get included in the preloader until *after* its done loading

const meshes = {
  landing: { scale: 1.0, yOffset: -2.3, url: "/assets/models/glb/spaceman-30k.glb" },
  about: { scale: 1.0, yOffset: -2.3, url: "/assets/models/glb/gogo-60k.glb" },
  nike: { scale: 1.0, yOffset: -2.0, url: "/assets/models/glb/nike-30k.glb" },
  gogo: { scale: 1.0, yOffset: -2.0, url: "/assets/models/glb/gogo-60k.glb" },
  msi: { scale: 1.0, yOffset: -2.0, url: "/assets/models/glb/u505-30k.glb" },
  beyonce: { scale: 1.0, yOffset: -2.0, url: "/assets/models/glb/beyonce-30k.glb" },
  jordan: { scale: 1.0, yOffset: -2.0, url: "/assets/models/glb/jumpman-30k.glb" }
};

for (const mesh in meshes) {
  const assetKey = assets.queue({
    url: meshes[mesh].url,
    key: mesh
  });
}

export default class Particles extends THREE.Object3D {
  constructor(props) {
    super();

    this.children = [];
    this.scene = null;
    this.transition = null;
    this.size = 512;
    this.simulationShader = new SimulationShader();
    this.renderShader = new RenderShader();
    this.simulation = new Simulation(this.size, webgl.renderer, this.simulationShader, this.renderShader);
    this.enableMouseMove = true;
    this.mouseX = window.innerWidth / 2;
    this.mouseY = window.innerHeight / 2;
    this.curlX = this.mouseX;
    this.curlY = this.mouseY;
    this.mouseEase = 0.3;

    // setup  a light
    // this.light = new THREE.AmbientLight(0xffffff);
    // this.add(this.light);

    this.add(this.simulation.particles);
    window.addEventListener("mousemove", this.onMouseMove);
  }

  /**
   * getModel method
   */
  setModel(key) {
    // now fetch the loaded resource
    let gltf = assets.get(key);

    let self = this;

    // Replaces all meshes material with something basic
    gltf.scene.traverse(child => {
      if (child.isMesh) {
        // set scale
        // this.simulationShader.uniforms.scale.value = meshes[key].scale;

        // set data texture
        self.setDataTexture(child);
        //   child.material = new THREE.MeshNormalMaterial();
        //   // ThreeJS attaches something odd here on GLTF ipmport
        //   child.onBeforeRender = () => {};
        //   this.children.push(child);
      }
    });
  }

  /**
   * setDataTexture method
   */
  setDataTexture(model) {
    let data = model.geometry.getAttribute("position");
    let size = Math.sqrt(data.count);
    let texture = new THREE.DataTexture(
      data.array,
      size,
      size,
      THREE.RGBFormat,
      THREE.FloatType,
      THREE.DEFAULT_MAPPING,
      THREE.ClampToEdgeWrapping,
      THREE.ClampToEdgeWrapping
    );
    texture.needsUpdate = true;

    if (this.transition == 1) {
      this.simulationShader.uniforms.textureA.value = texture;
      this.transition = 0;
    } else {
      this.simulationShader.uniforms.textureB.value = texture;
      this.transition = 1;
    }

    // animate.fromTo(
    //   this.simulationShader.uniforms.maxDistance,
    //   1,
    //   { value: 64 },
    //   { value: 16, yoyo: true, repeat: 1, ease: Expo.easeInOut }
    // );

    // animate.to(this.simulationShader.uniforms.transition, 3, {
    //   value: this.transition,
    //   ease: Expo.easeInOut
    // });

    this.animSwitch = new TimelineLite({ paused: true }).to(this.simulationShader.uniforms.transition, 1.5, {
      value: this.transition,
      ease: Expo.easeInOut
    });
    this.animSwitch.play();
  }

  onAppDidUpdate(oldProps, oldState, newProps, newState) {
    if (newState && newState.scene) {
      this.scene = newState.scene.client;
      this.setModel(this.scene);
      this.renderShader.setColor(newState.scene.backgroundFade, newState.scene.accentColor);
    }

    // console.log(`oldstate.explode: ${oldState.explode}`, `newState.explode: ${newState.explode}`);

    if (newState.explode === true) {
      this.explode();
    }

    if (newState.explode === false) {
      this.contract();
    }
  }

  contract(opt = {}) {
    this.inDistance = new TimelineLite({ paused: true }).to(this.simulationShader.uniforms.maxDistance, 1, {
      delay: opt.delay,
      value: 4,
      ease: Expo.easeOut
    });

    this.inOpacity = new TimelineLite({ paused: true }).to(this.renderShader.uniforms.opacity, 1, {
      delay: opt.delay,
      value: 1.0,
      ease: Expo.easeOut
    });

    this.inDistance.play();
    this.inOpacity.play();
    this.enableMouseMove = true;
    webgl.controls.autoRotate = true;
  }

  explode(opt = {}) {
    this.outDistance = new TimelineLite({ paused: true }).to(this.simulationShader.uniforms.maxDistance, 1, {
      delay: opt.delay,
      value: 512,
      ease: Expo.easeOut
    });

    this.outOpacity = new TimelineLite({ paused: true }).to(this.renderShader.uniforms.opacity, 1, {
      delay: opt.delay,
      value: 0.5,
      ease: Expo.easeOut
    });

    this.outDistance.play();
    this.outOpacity.play();
    this.enableMouseMove = false;
    webgl.controls.autoRotate = false;
  }

  update(dt = 0, time = 0) {
    this.simulation.update();
    this.simulationShader.update();

    this.curlX += (this.mouseX - this.curlX) * 0.1;
    this.curlY += (this.mouseY - this.curlY) * 0.1;

    if (this.enableMouseMove) {
      this.simulationShader.uniforms.cursorPos.value = new THREE.Vector2(this.curlX, this.curlY);
    }
  }

  onMouseMove = (ev, pos) => {
    this.mouseX = ev.pageX;
    this.mouseY = ev.pageY;
  };

  onTouchStart(ev, pos) {}

  onTouchMove(ev, pos) {
    this.mouseX = ev.originalEvent.touches[0].pageX;
    this.mouseY = ev.originalEvent.touches[0].pageY;
  }

  onTouchEnd(ev, pos) {}
}
