import React, { Component } from "react";
import styled from "styled-components";
import { Trail, animated, Transition } from "react-spring/renderprops";
import * as easings from "d3-ease";

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const Inner = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  text-align: center;
`;

const Pre = styled(animated.h1)`
  text-transform: uppercase;
  margin: 0.25rem auto;
  font-size: 2rem;
  overflow: hidden;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  will-change: height opacity;
`;

let items = [<Pre>getting</Pre>, <Pre>things</Pre>, <Pre>ready</Pre>];

export default class Preloader extends Component {
  constructor(props) {
    super(props);

    this.state = { items: [] };
    this.timer = null;

    this.phrases = [["Loading"], ["The"], ["Projects"], ["Sit"], ["Tight"]];
  }

  async componentDidMount() {
    this.timer && clearInterval(this.timer);

    let index = 0;

    this.timer = setInterval(() => {
      this.setState({ items: this.phrases[index] });
      index = (index + 1) % this.phrases.length;
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <Container>
        <Inner>
          <Transition
            native
            items={this.state.items}
            from={{ position: "absolute", opacity: 0, transform: "translate3d(0,-60px,0)" }}
            enter={{ opacity: 1, transform: "translate3d(0,0,0)" }}
            leave={{ opacity: 0, transform: "translate3d(0,60px,0)" }}
            trail={50}
            config={{ tension: 180, friction: 60, easing: easings.easeCubic }}
          >
            {item => styles => <Pre style={{ ...styles }}>{item}</Pre>}
          </Transition>
        </Inner>
      </Container>
    );
  }
}

Preloader.defaultProps = {};
