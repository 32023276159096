import React, { Component } from "react";
import styled from "styled-components";
import VirtualScroll from "virtual-scroll";
import { withRouter } from "react-router-dom";

const Container = styled.div`
  position: absolute;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  will-change: transform;
`;

export default class Nav extends Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();
    this.currentY = 0;
    this.scrollY = 0;
    this.ease = 0.1;
    this.scrollDirection = null;
    this.scroll = new VirtualScroll({
      mouseMultiplier: 0.15
    });
  }

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0);
    this.scrollY = 0;
    this.currentY = 0;
    this.setContainerHeight();
    // console.log(`Container Height on Update: ${this.containerHeight}`);
  }

  componentDidMount() {
    // handle resize events
    window.addEventListener("resize", () => this.resize());
    window.addEventListener("orientationchange", () => this.resize());

    this.setContainerHeight();
    // console.log(`Container Height on Mount: ${this.containerHeight}`);
    this.scroll.on(this.onScroll);
    this.update();
  }

  componentWillUnmount() {
    this.scroll.off(this.onScroll);
  }

  setContainerHeight = () => {
    this.containerHeight = this.container.current.firstChild.getBoundingClientRect().height;
    // console.log(`Container Height on Update: ${this.containerHeight}`);
  };

  resize = () => {
    this.setContainerHeight();
    // console.log(`Container Height on Resize: ${this.containerHeight}`);
  };

  onScroll = event => {
    this.scrollY += event.deltaY;
    this.scrollY = Math.max((this.containerHeight - window.innerHeight) * -1, this.scrollY);
    this.scrollY = Math.min(0, this.scrollY);
    this.handleScrollDirection(event);
    this.props.updateCurrentScroll(this.currentY, this.scrollDirection);
  };

  updateScroll = () => {
    this.currentY += (this.scrollY - this.currentY) * this.ease;
    let t = `translate3d(0, ${this.currentY}px, 0)`;
    let c = this.container.current.style;
    c["transform"] = t;
    c["webkitTransform"] = t;
    c["mozTransform"] = t;
    c["msTransform"] = t;
  };

  update = () => {
    this.updateScroll();
    requestAnimationFrame(this.update);
  };

  handleScrollDirection = event => {
    let delta;

    // console.log(event);

    if (event.deltaY) {
      delta = event.deltaY;
    } else {
      delta = -1 * event.deltaY;
    }

    if (delta < 0) {
      this.scrollDirection = "DOWN";
    } else if (delta > 0) {
      this.scrollDirection = "UP";
    }
  };

  render() {
    return (
      <Container ref={this.container} setContainerHeight={this.setContainerHeight}>
        {this.props.children}
      </Container>
    );
  }
}
