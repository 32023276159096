import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

const GlobalStyle = createGlobalStyle`
  ${reset}
  
  @font-face {
    font-family: "Netto OT";
    src: url("/assets/fonts/NettoOT-Bold.woff2") format("woff2"),
      url("/assets/fonts/NettoOT-Bold.woff") format("woff");
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Netto OT";
    src: url("/assets/fonts/NettoOT.woff2") format("woff2"),
      url("/assets/fonts/NettoOT.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Letter Gothic Std";
    src: url("/assets/fonts/LetterGothicStd.woff2") format("woff2"),
      url("/assets/fonts/LetterGothicStd.woff") format("woff");
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Letter Gothic Std";
    src: url("/assets/fonts/LetterGothicStd-Slanted.woff2") format("woff2"),
      url("/assets/fonts/LetterGothicStd-Slanted.woff") format("woff");
    font-weight: 500;
    font-style: italic;
  }

  :root {
    --font-body: "Letter Gothic Std", monospace;
    --font-headline: "Netto OT";
    --util-ease: cubic-bezier(.19,1,.22,1);
  }

  html, 
  body {
    height: 100%;
    overflow: hidden;
  }

  body {
    color: white;
    background: black;
    font-family: var(--font-body);
    letter-spacing: 0.3rem;
  }

  h1,
  h2,
  h3,
  h4,
  button {
    font-family: var(--font-headline);
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .is-uppercase {
    text-transform: uppercase;
  }

  .slash {
    position: relative;
    
    &:hover {
      &::after,
      &::before {
        width: 100%;
        left: -2px;
      }
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 33%;
      transform: translateY(-50%);
      width: 0;
      right: 0;
      height: 3px;
      pointer-events: none;
    }

    &::before {
      transition: width 0.4s cubic-bezier(0.51, 0.18, 0, 0.88) 0.1s;
      background: #222;
    }

    &::after {
      transition: width 0.2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
      background: #fff;
    }
  }
`;

export default GlobalStyle;
