import React, { Component } from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Trail, animated, config } from "react-spring/renderprops";
import InViewMonitor from "react-inview-monitor";
import * as easings from "d3-ease";

const Container = styled.div`
  text-align: left;
  position: absolute;
  z-index: 10;

  ${breakpoint("mobile")`
    font-size: 0.8rem;
    width: 73%;
    padding: 0 7% 0 20%;
  `};

  ${breakpoint("tablet")`
    font-size: 1rem;
    width: 70%;
    padding: 0 10% 0 20%;
  `};

  ${breakpoint("desktop")`
    font-size: 1rem;
    width: 60%;
    padding: 0 20%;
  `};

  .animated {
    transition: 2s var(--util-ease);
    will-change: transform;
  }

  .fadeOutUp {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }

  .fadeOutIn {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .fadeOut {
    opacity: 0;
  }

  .fadeIn {
    opacity: 1;
  }
`;

const Block = styled.div`
  line-height: 1.5rem;
  letter-spacing: 0.025rem;

  ${breakpoint("mobile")`
    margin-bottom: 10rem;
  `};

  ${breakpoint("tablet")`
    margin-bottom: 15rem;
  `};

  a {
    text-decoration: none;
    color: currentColor;
    transition: 0.2s;

    &:hover {
      color: white;
    }
  }

  &.title--mini {
    text-transform: uppercase;
    font-size: 0.8rem;
    margin-top: 4rem;
  }

  &.footnote {
    color: #777;
  }

  &.last {
    margin-bottom: 10rem;
  }

  &.columns {
    ${breakpoint("mobile")`
      column-count: 2;
    `};

    ${breakpoint("tablet")`
      column-count: 4;
    `};
  }

  &.clients {
    font-size: 0.75rem;
  }

  span {
    display: block;
    margin: 1rem auto 5rem;

    &.company {
      font-family: var(--font-headline);
      color: #fff;
      font-size: 1.5rem;
      margin: 1rem auto 0;
      text-transform: uppercase;
    }

    &.role {
      margin: 0.5rem auto 0;
      text-transform: uppercase;
      font-size: 0.75rem;
    }

    &.past {
      text-decoration: line-through;
    }
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  margin: 5rem auto 2rem;

  &.intro {
    ${breakpoint("mobile")`
      margin-top: 10rem;
    `};

    ${breakpoint("tablet")`
      margin-top: 15rem;
    `};
  }

  &.is-dark,
  .is-dark {
    color: #777;
  }

  .name {
    display: block;
  }

  ${breakpoint("mobile")`
    font-size: 2rem;
  `};

  ${breakpoint("tablet")`
    font-size: 3rem;
  `};
`;

const items = [
  <Title className="is-uppercase intro">
    Interactive Direction, Design + Code
    <span className="name is-dark">&mdash; Hey, I'm Austin Mayer</span>
  </Title>,
  <Block>
    I’m a designer who codes. I’m a coder who designs. I'm a digital strategist.
    I’m an on-the-box director that crafts digital expressions from concept to
    production for the most recognizable brands and artists in the world.
    <br />
    <br />
    Currently, I’m bringing digital design to life with YEEZY, Ivy Park,
    Pharrell and more as a UX Director at adidas. In the past, I’ve started
    companies, led multi-faceted creative teams for agencies, led product design
    for startups and created the future at a global powerhouse.
    <br />
    <br />
    Coming up with innovative ideas is easy. Seeing them through is hard. Lucky
    for you, I specialize in both.
  </Block>,
  <Title className="is-uppercase is-dark">&mdash; Say Hey</Title>,
  <Block className="footnote columns social">
    <ul>
      <li>
        <a
          href="https://twitter.com/amayer_"
          target="_blank"
          className="link slash"
        >
          Twitter
        </a>
      </li>{" "}
      <li>
        <a
          href="https://instagram.com/austinmayer"
          target="_blank"
          className="link slash"
        >
          Instagram
        </a>
      </li>{" "}
      <li>
        <a
          href="https://www.linkedin.com/in/austinmayer"
          target="_blank"
          className="link slash"
        >
          LinkedIn
        </a>
      </li>{" "}
      <li>
        <a
          href="mailto:howdy@austinmayer.co"
          target="_blank"
          className="link slash"
        >
          Email
        </a>
      </li>
    </ul>
  </Block>,
  // <Title className="is-uppercase is-dark">&mdash; Outposts</Title>,
  // <Block className="footnote">
  //   <span className="company">
  //     <a href="http://www.thefwa.com/" className="slash" target="_blank">
  //       FWA
  //     </a>
  //   </span>
  //   <span>Jury</span>
  //   <span className="company">Freelance</span>
  //   <span>
  //     Available for side projects, collaborations and cool opportunities involving any combination of design, strategy,
  //     creative direction, javascript, webgl, creative coding, lasers, etc.
  //   </span>
  //   <span className="company">
  //     <a href="https://nike.com" className="slash" target="_blank">
  //       Nike
  //     </a>
  //   </span>
  //   <span className="role">Experience Designer II, NXT Digital Innovation &mdash; Contract</span>
  //   <span>
  //     Exploring new ways of thinking about how modern technologies and services can be used to craft innovative
  //     experiences and products. Connecting Nike Explore Team strategic priorities through human centered experience
  //     design, strategy, creative direction and software prototyping.
  //   </span>
  //   <span className="company past">
  //     <a href="https://www.wired.com/story/syria-civil-war-hala-sentry/" target="_blank">
  //       Hala Systems
  //     </a>
  //   </span>
  //   <span className="role past">Experience Designer + Developer</span>
  //   <span className="past">
  //     I design and develop products that help quantify threats and protect civilians in conflict zones using javascript,
  //     native frameworks and user-centered design.
  //   </span>
  //   <span className="company past">
  //     <a href="http://www.lvthn.com/" target="_blank">
  //       Leviathan
  //     </a>
  //   </span>
  //   <span className="role past">Interactive Director</span>
  //   <span className="past">
  //     I work to bring digital moments to life in branded environments, browser experiences, installations and public
  //     spaces using creative coding frameworks, game engines and experience design.
  //   </span>
  //   <span className="company past">The Uprising Creative</span>
  //   <span className="role past">Interactive Director / Partner</span>
  //   <span className="past">A former boutique creative agency based in LA & Chicago.</span>
  //   <span className="company past">E&E</span>
  //   <span className="role past">Co-Founder</span>
  //   <span className="past">A former Chicago based digital studio.</span>
  // </Block>,
  <Title className="is-uppercase is-dark">&mdash; Clients</Title>,
  <Block className="footnote clients columns">
    Adidas
    <br /> Activision
    <br /> Air Jordan
    <br /> Adidas
    <br /> AT&T
    <br /> Converse
    <br /> Disney
    <br /> Gogo
    <br /> Intel
    <br /> Lollapalooza
    <br /> McDonald's
    <br /> MSI Chicago
    <br /> Nike
    <br /> Pepsi
    <br /> Samsung
    <br /> Sony Music
    <br /> United Center
    <br /> Virgin Hotels
    <br /> A$AP Rocky
    <br /> Beyoncé
    <br /> Demi Lovato
    <br /> John Mayer
    <br /> Justin Timberlake
    <br /> Kanye West
    <br /> The Rolling Stones
    <br /> and many, many more
  </Block>,
  <Title className="is-uppercase is-dark">&mdash; Speaking</Title>,
  <Block className="footnote last">
    Adobe Creative Jam Chicago 2018, Featured Speaker <br />
    Dart Conference 2018, Keynote Speaker
  </Block>,
];

export default class About extends Component {
  render() {
    return (
      <Container>
        <Trail
          items={items}
          keys={items.map((_, i) => i)}
          from={{ opacity: 0, transform: "translate3d(0,-40px,0)" }}
          to={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
          config={{ tension: 180, friction: 30, easing: easings.easeCubic }}
        >
          {(item) => (props) => <animated.div style={props} children={item} />}
        </Trail>
      </Container>
    );
  }
}
