import React, { Component } from "react";
import styled from "styled-components";
import { Spring, animated } from "react-spring/renderprops";

const Background = styled(animated.div)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -10;
  /* background: linear-gradient(-135deg, ${props => props.topright}, ${props => props.bottomleft}); */
`;

export default class Client extends Component {
  render() {
    let { data } = this.props;
    let backgroundColor = data ? data.backgroundColor : "#000";
    let backgroundFade = data ? data.backgroundFade : "#000";

    return (
      <>
        {this.props && (
          <Spring
            native
            duration={4000}
            from={{}}
            to={{
              background: `linear-gradient(-135deg, ${backgroundColor}, ${backgroundFade})`
            }}
          >
            {({ ...props }) => (
              <Background
                style={{
                  ...props
                }}
              />
            )}
          </Spring>
        )}
      </>
    );
  }
}
