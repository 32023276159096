import React, { Component } from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import ReactHtmlParser from "react-html-parser";
import ImagesLoaded from "react-images-loaded";
import InViewMonitor from "react-inview-monitor";
import isMobile from "ismobilejs";
import Video from "./Video";

// console.log(InViewMonitor.observer);

const Media = styled.div`
  max-width: 80%;
  text-align: center;
  z-index: 10;

  ${breakpoint("mobile")`
    margin: 5rem auto;
  `};
  ${breakpoint("tablet")`
    margin: 15rem auto;
  `};

  video {
    width: 100%;
    height: auto;
  }

  img,
  video {
    ${breakpoint("mobile")`
      margin: 1vh auto;
    `};
    ${breakpoint("tablet")`
      margin: 5vh auto;
    `};
  }

  .animated {
    transition: 2s var(--util-ease);
    will-change: transform;
  }

  .fadeOutUp {
    ${breakpoint("mobile")`
      opacity: 1;
    transform: translate3d(0, 0, 0);
    `};
    ${breakpoint("tablet")`
      opacity: 0;
    transform: translate3d(0, 100px, 0);
    `};
  }

  .fadeOutIn {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .fadeOut {
    opacity: 0;
  }

  .fadeIn {
    opacity: 1;
  }
`;

const MediaText = styled.div`
  display: block;
  text-align: justify;

  h3 {
    font-family: var(--font-headline);
    text-transform: uppercase;
    font-size: 2rem;
    margin: 0 auto 1rem;
    line-height: 2rem;
  }

  ${breakpoint("mobile")`
    width: 100%;
    font-size: 0.75rem;
    line-height: 1.2rem;
    margin: 10vh auto;
  `};
  ${breakpoint("tablet")`
    width: 50%;
    font-size: 0.9rem;
    line-height: 1.5rem;
    margin: 10vh auto 10vh 50%;
  `};
`;

export default class M extends Component {
  constructor(props) {
    super(props);
    this.state = { height: 0 };
    this.container = React.createRef();
  }

  handleOnAlways = instance => {};

  handleOnProgress = (instance, image) => {
    // console.log(instance);
    // this.props.updateContainerHeight();
  };

  handleOnFail = instance => {};

  handleDone = instance => {
    // console.log("Done Loading Image Assets");
    this.props.updateContainerHeight();
  };

  render() {
    let { data } = this.props;
    const media = data.media;

    return (
      <Media ref={this.container}>
        <ImagesLoaded
          onAlways={this.handleOnAlways}
          onProgress={this.handleOnProgress}
          onFail={this.handleOnFail}
          done={this.handleDone}
          key={window.location.href}
        >
          {media.map((item, index) => {
            if (item.endsWith(".jpg") || item.endsWith(".gif") || item.endsWith(".png")) {
              return (
                <InViewMonitor
                  classNameNotInView="animated fadeOutUp"
                  classNameInView="animated fadeInUp"
                  key={item + index}
                  // useInviewMonitor={() => !isMobile.any}
                >
                  <img key={item + index} src={item} />
                </InViewMonitor>
              );
            } else if (item.endsWith(".mp4") || item.endsWith(".webm")) {
              return (
                <InViewMonitor
                  childPropsInView={{ isPlaying: true }}
                  toggleChildPropsOnInView={true}
                  classNameNotInView="animated fadeOutUp"
                  classNameInView="animated fadeInUp"
                  key={item + index}
                  // useInviewMonitor={() => !isMobile.any}
                >
                  <Video key={item + index} src={item} />
                </InViewMonitor>
              );
            } else {
              return (
                <InViewMonitor
                  classNameNotInView="animated fadeOutUp"
                  classNameInView="animated fadeInUp"
                  key={item + index}
                  // useInviewMonitor={() => !isMobile.any}
                >
                  <MediaText key={item + index}>{ReactHtmlParser(item)}</MediaText>
                </InViewMonitor>
              );
            }
          })}
        </ImagesLoaded>
      </Media>
    );
  }
}
