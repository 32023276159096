import React, { Component } from "react";
import styled from "styled-components";
import { Spring, animated } from "react-spring/renderprops";
import * as easings from "d3-ease";

// Adapted from:
// https://www.npmjs.com/package/react-element-container
// Allows us to just use a regular DOM element as a child
// without having to deal with updates/reactive things.

const Container = styled(animated.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
`;

export default class ElementContainer extends Component {
  componentDidMount() {
    this.updateChild({});
  }

  shouldComponentUpdate(nextProps) {
    return this.props.child !== nextProps.child;
  }

  componentDidUpdate(prevProps) {
    this.updateChild(prevProps);
  }

  updateChild(prevProps) {
    const wrap = this.container;
    const next = this.props.child;
    const prev = prevProps.child;

    if (next) {
      wrap.appendChild(next);
    }

    if (prev && prev !== next && prev.parentNode === wrap) {
      wrap.removeChild(prev);
    }
  }

  render() {
    return (
      <Spring
        delay={1000}
        from={{ opacity: 0 }}
        to={{ opacity: 1 }}
        config={{ tension: 180, friction: 60, easing: easings.easeCubic }}
      >
        {props => (
          <Container
            style={props}
            id={this.props.id}
            ref={c => {
              this.container = c;
            }}
          />
        )}
      </Spring>
    );
  }
}
