import { EventEmitter } from "events";
import assign from "object-assign";
import defined from "defined";
import rightNow from "right-now";
import OrbitControls from "three-orbitcontrols";
import createTouches from "touches";
// import animate from "@jam3/gsap-promise";
import { BlendFunction, BloomEffect, SMAAEffect, EffectComposer, EffectPass, RenderPass } from "postprocessing";
import isMobile from "ismobilejs";

const tmpTarget = new THREE.Vector3();

export default class WebGLApp extends EventEmitter {
  constructor(opt = {}) {
    super();

    this.renderer = new THREE.WebGLRenderer(
      assign(
        {
          logarithmicDepthBuffer: true,
          antialias: false,
          alpha: true,
          failIfMajorPerformanceCaveat: true
        },
        opt
      )
    );

    this.canvas = this.renderer.domElement;

    // really basic touch handler that propagates through the scene
    this.touchHandler = createTouches(this.canvas, {
      target: this.canvas,
      filtered: true
    });
    this.touchHandler.on("start", (ev, pos) => this._traverse("onTouchStart", ev, pos));
    this.touchHandler.on("end", (ev, pos) => this._traverse("onTouchEnd", ev, pos));
    this.touchHandler.on("move", (ev, pos) => this._traverse("onTouchMove", ev, pos));

    // default background color
    const background = defined(opt.background, "#000");
    const backgroundAlpha = defined(opt.backgroundAlpha, 0);
    this.renderer.setClearColor(background, backgroundAlpha);

    // clamp pixel ratio for performance
    this.maxPixelRatio = defined(opt.maxPixelRatio, 2);

    // clamp delta to stepping anything too far forward
    this.maxDeltaTime = defined(opt.maxDeltaTime, 1 / 30);

    // setup a basic camera
    const fov = defined(opt.fov, 45);
    const near = defined(opt.near, 0.01);
    const far = defined(opt.far, 10000);
    this.camera = new THREE.PerspectiveCamera(fov, 1, near, far);
    this.camera.position.z = 2000;

    // orbit controls
    this.controls = new OrbitControls(this.camera, this.canvas);
    this.controls.enabled = false;
    this.controls.minDistance = isMobile.any ? 700 : 500;
    this.controls.maxDistance = isMobile.any ? 700 : 500;
    this.controls.enableDamping = true;
    this.controls.autoRotate = true;
    this.controls.enablePan = false;
    this.controls.enableKeys = false;
    this.screenSpacePanning = true;
    this.maxPolarAngle = 0;
    this.controls.autoRotateSpeed = 0.175;
    this.controls.dampingFactor = 0.15;

    this.time = 0;
    this._running = false;
    this._lastTime = rightNow();
    this._rafID = null;

    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.FogExp2(0x000000, 0.0025);
    // this.scene.add(new THREE.AxesHelper(200))

    // handle resize events
    window.addEventListener("resize", () => this.resize());
    window.addEventListener("orientationchange", () => this.resize());

    //  postprocessing
    this.composer = new EffectComposer(this.renderer);

    const areaImage = new Image();
    areaImage.src = SMAAEffect.areaImageDataURL;

    const searchImage = new Image();
    searchImage.src = SMAAEffect.searchImageDataURL;

    // passes
    const smaaEffect = new SMAAEffect(searchImage, areaImage);
    smaaEffect.setEdgeDetectionThreshold(0.05);
    smaaEffect.setOrthogonalSearchSteps(12);

    const bloomEffect = new BloomEffect({
      blendFunction: BlendFunction.SCREEN,
      resolutionScale: 0.5,
      distinction: 1.0
    });
    bloomEffect.blendMode.opacity.value = 2.1;

    // render pass
    this.renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(this.renderPass);

    // effect pass
    this.effectPass = new EffectPass(this.camera, smaaEffect, bloomEffect);

    // You only need to decide once if this pass renders to screen.
    this.effectPass.renderToScreen = true;
    this.composer.addPass(this.effectPass);

    // force an initial resize event
    this.resize();
  }

  get running() {
    return this._running;
  }

  animateIn(opt = {}) {
    this._traverse("animateIn", opt);
  }

  onAppDidUpdate(oldProps, oldState, newProps, newState) {
    this._traverse("onAppDidUpdate", oldProps, oldState, newProps, newState);
  }

  resize(width, height, pixelRatio) {
    // get default values
    width = defined(width, window.innerWidth);
    height = defined(height, window.innerHeight);
    pixelRatio = defined(pixelRatio, Math.min(this.maxPixelRatio, window.devicePixelRatio));

    this.width = width;
    this.height = height;
    this.pixelRatio = pixelRatio;

    // update pixel ratio if necessary
    if (this.renderer.getPixelRatio() !== pixelRatio) {
      this.renderer.setPixelRatio(pixelRatio);
    }

    // setup new size & update camera aspect if necessary
    this.renderer.setSize(width, height);
    if (this.camera.isPerspectiveCamera) {
      this.camera.aspect = width / height;
    }
    this.camera.updateProjectionMatrix();

    // draw a frame to ensure the new size has been registered visually
    this.draw();
    return this;
  }

  update(dt = 0, time = 0) {
    this.controls.update();

    // reposition to orbit controls
    // this.camera.up.fromArray(this.controls.up)
    // this.camera.position.fromArray(this.controls.position)
    // tmpTarget.fromArray(this.controls.target)
    // this.camera.lookAt(tmpTarget)

    // recursively tell all child objects to update
    this.scene.traverse(obj => {
      if (typeof obj.update === "function") {
        obj.update(dt, time);
      }
    });

    return this;
  }

  draw(dt) {
    this.renderer.render(this.scene, this.camera);
    // this.composer.render(dt);
    return this;
  }

  start() {
    if (this._rafID !== null) return;
    this._rafID = window.requestAnimationFrame(this.animate);
    this._running = true;
    return this;
  }

  stop() {
    if (this._rafID === null) return;
    window.cancelAnimationFrame(this._rafID);
    this._rafID = null;
    this._running = false;
    return this;
  }

  animate = () => {
    // <-- Note: using class functions thanks to a Babel plugin
    if (!this.running) return;
    window.requestAnimationFrame(this.animate);

    const now = rightNow();
    const dt = Math.min(this.maxDeltaTime, (now - this._lastTime) / 1000);
    this.time += dt;
    this._lastTime = now;
    this.update(dt, this.time);
    this.draw(dt);
  };

  _traverse = (fn, ...args) => {
    this.scene.traverse(child => {
      if (typeof child[fn] === "function") {
        child[fn](...args);
      }
    });
  };
}
