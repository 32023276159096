import vertexShader from "../../shaders/particles/render.vert";
import fragmentShader from "../../shaders/particles/render.frag";
import { TweenMax } from "gsap";
import isMobile from "ismobilejs";

let pointSize = isMobile.any ? 3 : 3;
let nfReg = new THREE.Vector2(-5, 15);
let nfMobile = new THREE.Vector2(-5, 15);
let nearFar = isMobile.any ? nfMobile : nfReg;

/**
 * FBOMaterial class
 */
export default class RenderShader extends THREE.ShaderMaterial {
  /**
   * constructor method
   * @param {Object} options Options
   */
  constructor(options) {
    super(options);

    this.vertexShader = vertexShader;
    this.fragmentShader = fragmentShader;
    this.transparent = true;
    this.side = THREE.DoubleSide;
    // this.blending = THREE.AdditiveBlending;
    this.blending = THREE.NoBlending;

    // this.uniforms = {
    //   positions: { type: "t", value: null },
    //   pointSize: { type: "f", value: 4.0 },
    //   big: {
    //     type: "v3",
    //     value: new THREE.Vector3(111, 221, 212).multiplyScalar(0.5 / 0xff)
    //   },
    //   small: {
    //     type: "v3",
    //     value: new THREE.Vector3(213, 239, 229).multiplyScalar(1.5 / 0xff)
    //   }
    // };

    this.uniforms = {
      positions: { type: "t", value: null },
      pointSize: { type: "f", value: pointSize },
      nearFar: { type: "v2", value: nearFar },
      color1: { type: "c", value: new THREE.Color(0x444444) },
      color2: { type: "c", value: new THREE.Color(0x000000) },
      hardness: { type: "f", value: 0.5 },
      opacity: { type: "f", value: 1.0 }
    };
  }

  onAppDidUpdate(oldProps, oldState, newProps, newState) {
    console.log("renderShader chh chhhh changes: ", oldProps, oldState, newProps, newState);
    // this.scene = newState.scene;
    // this.setColor(newState.scene);
  }

  /**
   * setColor method
   */
  setColor(primaryColor, secondaryColor) {
    // let project = data.find(project => project.client === name);
    let formattedPrimaryColor = parseInt("0x" + primaryColor.replace(/\#/g, ""));
    let formattedSecondaryColor = parseInt("0x" + secondaryColor.replace(/\#/g, ""));

    let color1 = new THREE.Color(formattedPrimaryColor);
    let color2 = new THREE.Color(formattedSecondaryColor);

    TweenMax.to(this.uniforms.color1.value, 2.5, {
      r: color1.r,
      g: color1.g,
      b: color1.b
    });
    TweenMax.to(this.uniforms.color2.value, 2.5, {
      r: color2.r,
      g: color2.g,
      b: color2.b
    });
  }

  /**
   * update method
   * @param {number} time Time
   */
  update(time) {}
}
