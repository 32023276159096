import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Transition, animated } from "react-spring/renderprops";
import styled from "styled-components";
import createHistory from "history/createBrowserHistory";
import ReactGA from "react-ga";

// Data
import projects from "../data/projects.json";
import pages from "../data/pages.json";

// DOM Sections
import Header from "../components/Header";
import Background from "../components/Background";
import Landing from "../sections/Landing";
import Preloader from "../sections/Preloader";
import Work from "../sections/Work";
import About from "../sections/About";
import NotFound from "../sections/NotFound";

// VirtualScroll
import VirtualScroll from "./VirtualScroll";

// WebGL canvas component
import WebGLCanvas from "../components/WebGLCanvas";

// WebGL scenes
import Particles from "../webgl/scene/Particles";

// WebGL Context
import { assets, webgl } from "../context";

// Global Style
import GlobalStyle from "./GlobalStyle";

const Container = styled(animated.div)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const history = createHistory();
ReactGA.initialize("UA-72008715-1");

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      section: "Preloader",
      scene: pages[0],
      explode: false,
      webGLEnabled: true
    };

    this.scrolled = false;
    this.scrollRef = React.createRef();
    this.particles = new Particles();
  }

  handlePreventDefault = ev => {
    ev.preventDefault();
  };

  componentDidUpdate(oldProps, oldState) {
    if (this.state.isLoaded && oldState.isLoaded !== this.state.isLoaded) {
      // start animation loop
      webgl.start();

      // draw a frame so that its correct on first DOM render
      webgl.draw();

      // trigger initial animation in of content
      webgl.animateIn({ delay: 2.0 });
    }

    // propagate through entire scene graph any app changes
    webgl.onAppDidUpdate(oldProps, oldState, this.props, this.state);
  }

  componentWillMount() {
    ReactGA.set({ page: "/" });
    ReactGA.pageview("/");
  }

  componentDidMount() {
    // To avoid page pulling, text highlighting and such
    webgl.canvas.addEventListener("touchstart", this.handlePreventDefault);
    webgl.canvas.addEventListener("mousedown", this.handlePreventDefault);

    this.setScene(window.location);

    // setup history listener
    // setScene based on URL route in app
    history.listen((location, action) => {
      this.setScene(location);
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
      // console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`);
      // console.log(`The last navigation action was ${action}`);
    });

    this.loadWebGL();
  }

  componentWillUnmount() {
    webgl.canvas.removeEventListener("touchstart", this.handlePreventDefault);
    webgl.canvas.removeEventListener("mousedown", this.handlePreventDefault);
  }

  loadWebGL() {
    // Preload any queued assets
    assets.loadQueued(() => {
      // Do some fake delay for demo purposes
      setTimeout(() => {
        // Once loading is complete, swap to Landing section and ensure WebGL displays
        this.setState({ section: "Landing", isLoaded: true });
      }, this.props.fakePreloadTime);

      // Add any "WebGL components" here...
      webgl.scene.add(this.particles);
    });
  }

  getContent(section) {
    switch (section) {
      case "Preloader":
        return <Preloader key="Preloader" />;
      default:
      case "Landing":
        return (
          <Router history={history}>
            <>
              <Header />
              <VirtualScroll ref={this.scrollRef} updateCurrentScroll={this.updateCurrentScroll}>
                <Switch location={location}>
                  <Route exact path="/" render={props => <Landing {...props} />} />
                  <Route
                    path="/work/:slug"
                    key={location.path}
                    render={props => (
                      <Work
                        {...props}
                        project={this.state.scene}
                        explodeParticles={this.explodeParticles}
                        updateContainerHeight={this.updateContainerHeight}
                      />
                    )}
                  />
                  <Route path="/about" render={props => <About {...props} />} />
                  <Route render={props => <NotFound {...props} enableWebGL={this.enableWebGL} />} />
                </Switch>
              </VirtualScroll>
            </>
          </Router>
        );
    }
  }

  setScene = location => {
    let scene;
    let explode;
    let workItemVisible;
    let path = location.pathname;
    let slug = location.pathname.split("/").slice(-1)[0];

    if (path === "/") {
      scene = pages[0];
      explode = false;
      workItemVisible = false;
    } else if (path.includes("work")) {
      scene = projects.find(scene => scene.client === slug);
      explode = false;
    } else {
      scene = pages.find(scene => scene.client === slug);
      explode = true;
      workItemVisible = false;
    }

    this.setState({ scene: scene, explode: explode, workItemVisible, webGLEnabled: true });
  };

  explodeParticles = explode => {
    this.setState({ explode: explode });
  };

  enableWebGL = enabled => {
    this.setState({ webGLEnabled: enabled });
  };

  updateContainerHeight = () => {
    this.scrollRef.current.setContainerHeight();
  };

  updateCurrentScroll = (scrollY, scrollDirection) => {
    this.lastScroll = -Math.round(scrollY);

    if (window.location.href.includes("work")) {
      if (this.lastScroll >= 50 && scrollDirection == "DOWN" && !this.scrolled) {
        // Trigger Particle Class Method directly, state change re-renders App
        // Fucks up Virtual Scroll resetting back to 0 :(
        this.particles.explode();
        this.scrolled = true;
      }
      if (this.lastScroll <= 50 && scrollDirection == "UP" && this.scrolled) {
        // Trigger Particle Class Method directly, state change re-renders App
        // Fucks up Virtual Scroll resetting back to 0 :(
        this.particles.contract();
        this.scrolled = false;
      }
    }
  };

  render() {
    const section = this.state.section;
    const content = this.getContent(section);

    // Render the WebGL if loaded
    // And also render the current UI section on top, with transitions
    return (
      <>
        <GlobalStyle />
        {content}
        {this.state.isLoaded && this.state.webGLEnabled && <WebGLCanvas />}
        <Background data={this.state.scene} />
      </>
    );
  }
}

App.defaultProps = {
  // Artificially inflate preload time so
  // we can see it for demo purposes
  fakePreloadTime: 1500
};
