import WebGLApp from "./webgl/WebGLApp";
import AssetManager from "./util/AssetManager";
import query from "./util/query";
// import dat from "./vendor/dat.gui";

// Setup dat.gui
// const gui = new dat.GUI();

// if (!query.gui) {
//   document.querySelector(".dg.ac").style.display = "none";
// }

// Create a canvas
const canvas = document.createElement("canvas");

// Setup the WebGLRenderer
const webgl = new WebGLApp({
  background: "white",
  canvas
});

// Setup an asset manager
const assets = new AssetManager({
  renderer: webgl.renderer
});

export { assets, canvas, webgl };
