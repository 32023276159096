import vertexShader from "../../shaders/particles/simulation.vert";
import fragmentShader from "../../shaders/particles/simulation.frag";
import { webgl } from "../../../context";

/**
 * SimulationShader class
 */
export default class SimulationShader extends THREE.ShaderMaterial {
  /**
   * constructor method
   * @param {Object} options Options
   */
  constructor(options) {
    super(options);

    this.vertexShader = vertexShader;
    this.fragmentShader = fragmentShader;

    this.uniforms = {
      textureA: { type: "t", value: null },
      textureB: { type: "t", value: null },
      timer: { type: "f", value: 0 },
      transition: { type: "f", value: 1 },
      frequency: { type: "f", value: 0.1 },
      amplitude: { type: "f", value: 2.0 },
      maxDistance: { type: "f", value: 3.0 },
      scale: {
        type: "f",
        value: 0.65
      },
      resolution: {
        type: "v2",
        value: new THREE.Vector2(webgl.width, webgl.height)
      },
      cursorPos: {
        type: "v2",
        value: new THREE.Vector2(0, 0)
      },
      mouseStrength: {
        type: "f",
        value: 1.0
      },
      pm: {
        type: "m4",
        value: webgl.camera.projectionMatrix
      },
      vm: {
        type: "m4",
        value: webgl.camera.matrixWorldInverse
      }
    };
  }

  /**
   * update method
   * @param {number} time Time
   */
  update(time) {
    this.uniforms.timer.value += 0.01;
  }
}
