import React, { Component } from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Keyframes, Transition, animated } from "react-spring/renderprops";
import * as easings from "d3-ease";

import Media from "./Media";

const Container = styled.div`
  text-align: center;
  position: absolute;

  z-index: 10;
  letter-spacing: 0.025rem;

  ${breakpoint("mobile")`
    width: 90%;
    margin-left: 10%;
  `};
  ${breakpoint("tablet")`
    width: 93%;
    margin-left: 7%;
  `};
`;

const Client = styled.div`
  text-align: center;
  font-family: var(--font-headline);
  color: ${props => props.color || "white"};
  will-change: opacity transform;

  ${breakpoint("mobile")`
    margin-top: 60vh;
    font-size: 5rem;
  `};
  ${breakpoint("tablet")`
    margin-top: 25%;
    font-size: 10rem;
  `};
`;

const Details = styled(animated.div)`
  line-height: 1.75rem;
  text-align: center;
  color: white;
  opacity: 0.9;
  letter-spacing: 0.025rem;
  margin: 0 auto;
  z-index: 10;
  will-change: opacity transform;

  ${breakpoint("mobile")`
    max-width: 80vw;
    font-size: 0.8rem;
    line-height: 1.2rem;
  `};
  ${breakpoint("tablet")`
    max-width: 40vw;
    font-size: 0.8rem;
    line-height: 1.5rem;
  `};
`;

const Type = styled.div`
  opacity: 0.9;
  font-size: 0.75rem;
  margin-top: 1rem;
  text-transform: uppercase;
  text-align: center;
  color: #aaa;
  will-change: opacity transform;
`;

const Year = styled.div``;

const Skills = styled.div`
  font-size: 0.75rem;
  text-align: center;
  margin-top: 2rem;
  line-height: 1.5rem;
  will-change: opacity transform;

  ${breakpoint("mobile")`
    padding: 0 4rem;
  `};
  ${breakpoint("tablet")`
    padding: 0;
  `};
`;

const Scroll = styled.div`
  position: absolute;
  left: 50%;
  width: 1px;
  overflow: hidden;
  opacity: 0.5;
  will-change: opacity transform;

  ${breakpoint("mobile")`
    height: 30px;
    bottom: -3rem;
  `};
  ${breakpoint("tablet")`
    height: 60px;
    bottom: -6rem;
  `};

  @keyframes scroll {
    from {
      transform: translate3d(0, -100%, 0);
    }

    to {
      transform: translate3d(0, 100%, 0);
    }
  }

  .inner {
    background: #fff;
    height: 100%;
    width: 100%;
    animation-name: scroll;
    animation-duration: 1.75s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-delay: 0s;
    animation-timing-function: var(--util-ease);
  }
`;

const Content = Keyframes.Trail({
  in: { y: 0, opacity: 1, delay: 500 },
  out: { y: 100, opacity: 0, delay: 0 }
});

export default class Work extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      visible: false
    };
  }

  componentWillMount() {
    this.setState({ project: this.props.project });
  }

  componentWillReceiveProps(props) {
    if (props.project !== this.state.project) {
      this.animatePage(props.project);
    }
  }

  componentDidMount() {
    this.animatePage(this.state.project);
  }

  animatePage = project => {
    this.setState({ visible: false });
    setTimeout(() => {
      this.setState({ project: project, visible: true });
    }, 750);
  };

  render() {
    const animationState = this.state.visible ? "in" : "out";

    const items = [
      <Client className="is-uppercase" color={this.state.project.accentColor} key={this.state.project.client}>
        {this.state.project.client}
      </Client>,
      <Details key={this.state.project.details}>{this.state.project.details}</Details>,
      <Type key={this.state.project.type}>{this.state.project.type}</Type>,
      <Skills key={this.state.project.skills}>{this.state.project.skills}</Skills>,
      <Scroll key={this.state.project.skills}>
        <div className="inner" />
      </Scroll>
    ];

    return (
      <Container>
        {this.state.project && (
          <>
            <Content
              native
              items={items}
              keys={items.map((_, i) => window.location.href + i)}
              reverse={!this.state.visible}
              state={animationState}
              config={{ tension: 180, friction: 30, easing: easings.easeCubic }}
            >
              {item => ({ y, opacity }) => (
                <animated.div
                  style={{
                    opacity,
                    transform: y.interpolate(y => `translate3d(0,${y}px,0)`)
                  }}
                >
                  {item}
                </animated.div>
              )}
            </Content>

            {/* <TopPart>
          <TopPartInner>
            <Transition
              unique
              reset
              native
              items={items}
              keys={items.map((_, i) => window.location.href + i)}
              from={{ opacity: 0, transform: "translate3d(0, 100px, 0)" }}
              enter={{ opacity: 1, transform: "translate3d(0, 0px, 0)" }}
              leave={{ transform: "translate3d(0, -400px, 0)" }}
              config={{ tension: 280, friction: 30, easing: easings.easeCubic }}
              trail={100}
            >
              {item => props => <animated.div style={props}>{item}</animated.div>}
            </Transition>
          </TopPartInner>
        </TopPart> */}

            <Media data={this.state.project} updateContainerHeight={this.props.updateContainerHeight} />
          </>
        )}
      </Container>
    );
  }
}
