import React, { Component } from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Trail, animated, config } from "react-spring/renderprops";
import * as easings from "d3-ease";

const Container = styled.div`
  text-align: center;
  position: absolute;
  width: 93%;
  margin-left: 7%;
  height: 100%;
  z-index: 10;
`;

const InnerContainer = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`;

const Name = styled.h1`
  font-size: 5rem;
`;

const Title = styled.p`
  font-size: 1.5rem;
  margin: 2rem auto;
  line-height: 1.75rem;
`;

const items = [
  <Name className="is-uppercase">Austin Mayer</Name>,
  <Title className="is-uppercase">Interactive Director</Title>
];

export default class Landing extends Component {
  render() {
    return (
      <Container>
        <InnerContainer>
          <Trail
            delay={2000}
            items={items}
            keys={items.map((_, i) => i)}
            from={{ opacity: 0, transform: "translate3d(0,-40px,0)" }}
            to={{ opacity: 1, transform: "translate3d(0,0px,0)" }}
            config={{ tension: 180, friction: 30, easing: easings.easeCubic }}
          >
            {item => props => <animated.div style={props} children={item} />}
          </Trail>
        </InnerContainer>
      </Container>
    );
  }
}
